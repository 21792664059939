@mixin clearfix {
  &:after {
    content: "\0020";
    display: block;
    height: 0;
    clear: both;
    overflow: hidden;
    visibility: hidden;
  }
}

@mixin cartIcon {
  @include sprite($basket-top);

  padding-right:2px;
  float:left;
  margin:15px 0 0 15px;
  text-align:center;
  display:block;

  span {
    background:#bf342e;
    border-radius:2px;
    color:#FFFFFF;
    padding:0 0.25em;
    font-size: 12px;
  }
}

@mixin checkout {
  background:#9c1616;
  letter-spacing: 0.5px;
  line-height:1.8em;
  text-transform:uppercase;
  text-decoration:none;
  color:#FFFFFF;
  display:block;
  float:left;
  padding:0 1em;
  margin:14px 0 0 15px;
  border-top:1px solid #c67979;
  border-right:1px solid #821212;
  border-bottom:1px solid #731010;
  border-left:1px solid #b14747;
  font-size: 105%;
}

@mixin input_search {
  background: #f8f8f8;
  border:1px solid #bbb;
  width:220px;
  padding:4px;
  margin-right:8px;
  display: inline-block;
  -webkit-appearance: textfield;
  outline: none;
  vertical-align: middle;
  border-radius: 2px;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$basket-top-name: 'basketTop';
$basket-top-x: 0px;
$basket-top-y: 158px;
$basket-top-offset-x: 0px;
$basket-top-offset-y: -158px;
$basket-top-width: 29px;
$basket-top-height: 25px;
$basket-top-total-width: 193px;
$basket-top-total-height: 183px;
$basket-top-image: '~sprite.png';
$basket-top: (0px, 158px, 0px, -158px, 29px, 25px, 193px, 183px, '~sprite.png', 'basketTop', );
$crumbs-arrow-name: 'crumbsArrow';
$crumbs-arrow-x: 187px;
$crumbs-arrow-y: 50px;
$crumbs-arrow-offset-x: -187px;
$crumbs-arrow-offset-y: -50px;
$crumbs-arrow-width: 4px;
$crumbs-arrow-height: 5px;
$crumbs-arrow-total-width: 193px;
$crumbs-arrow-total-height: 183px;
$crumbs-arrow-image: '~sprite.png';
$crumbs-arrow: (187px, 50px, -187px, -50px, 4px, 5px, 193px, 183px, '~sprite.png', 'crumbsArrow', );
$facebook-login-name: 'facebookLogin';
$facebook-login-x: 0px;
$facebook-login-y: 50px;
$facebook-login-offset-x: 0px;
$facebook-login-offset-y: -50px;
$facebook-login-width: 185px;
$facebook-login-height: 40px;
$facebook-login-total-width: 193px;
$facebook-login-total-height: 183px;
$facebook-login-image: '~sprite.png';
$facebook-login: (0px, 50px, 0px, -50px, 185px, 40px, 193px, 183px, '~sprite.png', 'facebookLogin', );
$google-login-normal-name: 'googleLoginNormal';
$google-login-normal-x: 0px;
$google-login-normal-y: 0px;
$google-login-normal-offset-x: 0px;
$google-login-normal-offset-y: 0px;
$google-login-normal-width: 193px;
$google-login-normal-height: 48px;
$google-login-normal-total-width: 193px;
$google-login-normal-total-height: 183px;
$google-login-normal-image: '~sprite.png';
$google-login-normal: (0px, 0px, 0px, 0px, 193px, 48px, 193px, 183px, '~sprite.png', 'googleLoginNormal', );
$no-photo-name: 'noPhoto';
$no-photo-x: 0px;
$no-photo-y: 92px;
$no-photo-offset-x: 0px;
$no-photo-offset-y: -92px;
$no-photo-width: 64px;
$no-photo-height: 64px;
$no-photo-total-width: 193px;
$no-photo-total-height: 183px;
$no-photo-image: '~sprite.png';
$no-photo: (0px, 92px, 0px, -92px, 64px, 64px, 193px, 183px, '~sprite.png', 'noPhoto', );
$no-photo-small-name: 'noPhotoSmall';
$no-photo-small-x: 140px;
$no-photo-small-y: 122px;
$no-photo-small-offset-x: -140px;
$no-photo-small-offset-y: -122px;
$no-photo-small-width: 15px;
$no-photo-small-height: 15px;
$no-photo-small-total-width: 193px;
$no-photo-small-total-height: 183px;
$no-photo-small-image: '~sprite.png';
$no-photo-small: (140px, 122px, -140px, -122px, 15px, 15px, 193px, 183px, '~sprite.png', 'noPhotoSmall', );
$scrollable-left-name: 'scrollableLeft';
$scrollable-left-x: 66px;
$scrollable-left-y: 92px;
$scrollable-left-offset-x: -66px;
$scrollable-left-offset-y: -92px;
$scrollable-left-width: 35px;
$scrollable-left-height: 51px;
$scrollable-left-total-width: 193px;
$scrollable-left-total-height: 183px;
$scrollable-left-image: '~sprite.png';
$scrollable-left: (66px, 92px, -66px, -92px, 35px, 51px, 193px, 183px, '~sprite.png', 'scrollableLeft', );
$scrollable-right-name: 'scrollableRight';
$scrollable-right-x: 103px;
$scrollable-right-y: 92px;
$scrollable-right-offset-x: -103px;
$scrollable-right-offset-y: -92px;
$scrollable-right-width: 35px;
$scrollable-right-height: 51px;
$scrollable-right-total-width: 193px;
$scrollable-right-total-height: 183px;
$scrollable-right-image: '~sprite.png';
$scrollable-right: (103px, 92px, -103px, -92px, 35px, 51px, 193px, 183px, '~sprite.png', 'scrollableRight', );
$search-name: 'search';
$search-x: 170px;
$search-y: 92px;
$search-offset-x: -170px;
$search-offset-y: -92px;
$search-width: 20px;
$search-height: 20px;
$search-total-width: 193px;
$search-total-height: 183px;
$search-image: '~sprite.png';
$search: (170px, 92px, -170px, -92px, 20px, 20px, 193px, 183px, '~sprite.png', 'search', );
$zoom-name: 'zoom';
$zoom-x: 140px;
$zoom-y: 92px;
$zoom-offset-x: -140px;
$zoom-offset-y: -92px;
$zoom-width: 28px;
$zoom-height: 28px;
$zoom-total-width: 193px;
$zoom-total-height: 183px;
$zoom-image: '~sprite.png';
$zoom: (140px, 92px, -140px, -92px, 28px, 28px, 193px, 183px, '~sprite.png', 'zoom', );
$spritesheet-width: 193px;
$spritesheet-height: 183px;
$spritesheet-image: '~sprite.png';
$spritesheet-sprites: ($basket-top, $crumbs-arrow, $facebook-login, $google-login-normal, $no-photo, $no-photo-small, $scrollable-left, $scrollable-right, $search, $zoom, );
$spritesheet: (193px, 183px, '~sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}

@import 'app';
